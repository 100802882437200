<template>
  <v-container fluid>
    <v-card raised v-if="showScreen">
      <loading :active.sync="loading" />
      <v-card-title class="bg-clr" style="padding: 16px !important">
        <v-layout column wrap>
          <div class="headline">Additional Charge List</div>
          <v-spacer />
          <!-- <div class="heading py-1 muted">
            Milestone helps in tracking assignments
          </div> -->
        </v-layout>
        <v-spacer />
        <v-btn
          v-permissions="'add-additional-charge-name'"
          @click="checkAdd()"
          class="white--text"
          flat
          color="orange darken-1"
        >
          <v-icon>add</v-icon>&nbsp;Add New Additional Charge
        </v-btn>
      </v-card-title>

      <v-container fluid id="filter_wrap">
        <v-layout row pt-0 style="justify-content: space-between">
          <v-flex xs6 pt-3>
            <v-text-field
              class="pt-0 currencyTitle"
              v-model="additionalChargeName"
              label="Search Additional Charge Name"
              single-line
              @input="searchData"
              @click:append="searchData"
              autocomplete="offfadsf"
              append-icon="search"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs1 class="reset-btn" mt-2 pl-4>
            <v-card-actions class="justify-end pt-1">
              <v-btn
                class="reset-btn-color"
                style="padding: 12px !important"
                flat
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>

      <v-layout row wrap align-center>
        <v-flex md12 class="communication">
          <v-card class="nospace">
            <v-card-text class="nospace">
              <v-data-table
                id="MilestoneListTable"
                hide-actions
                :headers="headers"
                :items="items"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    {{ props.index + 1 + pgNo }}
                    <activityLogs
                      :type="'12'"
                      :sourceId="props.item.sourceId"
                      :subType="16"
                    />
                  </td>
                  <td v-if="props.item.country">
                    {{ props.item.country }}
                  </td>
                  <td v-else>-</td>
                  <td>
                    <div>
                      {{ capitalize(props.item.source) || "N.A" }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ capitalize(props.item.destination) || "N.A" }}
                    </div>
                  </td>
                  <td style="padding: 20px !important">
                    {{ getAdditionalChargeNameFromList(props.item.list) }}
                  </td>
                  <td
                    v-if="
                      checkListStatus(
                        props.item.status,
                        props.item.sourceDestinationChangeStatus
                      ) == 'PENDING'
                    "
                  >
                    <span class="red-text"> PENDING </span>
                  </td>
                  <td v-else>
                    <span class="green-text"> APPROVED</span>
                  </td>
                  <td>
                    <v-layout style="grid-gap: 2px">
                      <v-flex
                        v-if="
                          checkListStatus(
                            props.item.status,
                            props.item.sourceDestinationChangeStatus
                          ) == 'PENDING'
                        "
                      >
                        <v-tooltip
                          top
                          content-class="tooltip-op"
                          color="orange darken-1"
                        >
                          <template slot="activator">
                            <v-icon
                              v-permissions="'approve-additional-charge-name'"
                              prepend-icon
                              @click.native="
                                checkApprove(
                                  props.item.list,
                                  props.item.sourceId,
                                  'Approve',

                                  props.item.source,
                                  props.item.destination
                                )
                              "
                              class="outlined"
                              title="Approve Additional Charge"
                              color="green"
                              >check</v-icon
                            >
                          </template>
                          <span class="tooltip">Approve Additional Charge</span>
                        </v-tooltip></v-flex
                      >
                      <v-flex
                        xs3
                        v-if="
                          checkListStatus(
                            props.item.status,
                            props.item.sourceDestinationChangeStatus
                          ) !== 'PENDING'
                        "
                      ></v-flex>
                      <v-flex>
                        <!-- <editAdditionalChargeName :allData="props.item" /> -->
                        <button
                          outline
                          class="tra-ass-btn"
                          type="button"
                          @click.stop="checkRouteEdit(props.item.sourceId)"
                        >
                          <v-icon
                            color="grey darken-1"
                            flat
                            prepend-icon
                            class="customer-actions"
                            v-permissions="'edit-additional-charge-name'"
                            >create
                          </v-icon>
                        </button>
                      </v-flex>

                      <v-flex>
                        <v-tooltip
                          top
                          content-class="tooltip-op"
                          color="orange darken-1"
                        >
                          <template slot="activator">
                            <v-icon
                              v-permissions="'delete-additional-charge-name'"
                              color="orange darken-1"
                              flat
                              prepend-icon
                              @click.native="
                                checkDelete(
                                  props.item.list,
                                  props.item.sourceId,
                                  'Delete'
                                )
                              "
                              class="milestone-actions"
                              title="Delete Additional Charge"
                              >delete</v-icon
                            >
                          </template>
                          <span class="tooltip">Delete Additional charge</span>
                        </v-tooltip></v-flex
                      >
                    </v-layout>
                  </td>
                </template>
              </v-data-table>

              <pagination
                :totalPages="totalPages"
                :pages="this.pages"
                @pageNo="page"
                :url="paginationURL"
                :componentKey="componentKey"
                :callback="callback"
                :type="0"
                :action="`additionalChargeList`"
                v-on:pagination-load="checkLoading"
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>

    <success-dialog
      :content="successDialogText"
      :show="x.success"
      :onclose="closeMessage"
    />
    <SelectCountry
      :open="selectCountryDialog"
      @closeSelectCountry="selectCountryDialog = false"
      :type="`/add-new-additionalCharge`"
    />
    <ErrorBox :error="x.error" />
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="agree">Yes</v-btn>
          <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import pagination from "@/components/Pagination/pagination";
import { additionalChargeNameList } from "@/constants/datatable-headers.js";
import activityLogs from "../Common/activityLogs";
import SelectCountry from "../Common/SelectCountry";
import Loading from "vue-loading-overlay";
// import editAdditionalChargeName from "../AdditionalCharges/editAdditionalChargeName.vue";

import { EventBus } from "../../event-bus.js";
import Swal from "sweetalert2";
import { StorageKeys } from "../../constants/constants";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import {
  milestoneListDetails,
  additionalChargeListDetails,
  removeMilestone,
  editAdditionalCharge,
} from "../../constants/api-urls";
export default {
  components: {
    activityLogs,
    ErrorBox,
    SuccessDialog,
    Loading,
    pagination,
    // editAdditionalChargeName,
    SelectCountry,
  },
  created() {
    // EventBus.$on("additional-charge-update", (value) => {
    //   console.log("socket working", value);
    //   this.refresh();
    // });
    this.$eventBus.$on("additionalchargeUpdate", (value) => {
      // console.log("socket working", value);
      this.refresh();
    });
    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.paginationURL = `/dmsAdmin/get-all-additional-chargeName?workingCountry=${this.workingCountry}`;
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      this.paginationURL = `/dmsAdmin/get-all-additional-chargeName?workingCountry=${this.workingCountry}`;
    }

    // this.getAdditionalChargeList();
  },

  data() {
    return {
      showScreen: true,
      workingCountry: null,
      selectCountryDialog: false,
      operationName: "",
      loading: true,
      isNoSearchResult: false,
      componentKey: 0,
      totalPages: 0,
      paginationURL: "",
      additionalType: "",
      additionalChargeName: "",
      pages: 1,
      headers: additionalChargeNameList,
      successDialogText: "",

      pgNo: Number,
      items: [],
      x: {
        error: null,
        message: null,
        success: false,
      },
      show: true,
      deleteId: [],
      approvedId: [],
      pagination: {},
      sourceCountry: "",
      destinationCountry: "",
      sourceId: "",
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: "#ffc04c",
        width: 590,
        zIndex: 200,
      },
    };
  },
  methods: {
    checkRouteEdit(sourceId) {
      this.$router.push(`/edit-additionalCharge/${sourceId}`);
    },

    page(event) {
      this.pages = event;
    },
    async callback(res, pgno) {
      this.pgNo = pgno > 1 ? (pgno - 1) * 15 : 0;
      // console.log(pgno, "------pgeno");
      // console.log(res.list);
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
      // console.log(this.pagination);
      this.pagination.rowsPerPage = 15;
      window.scrollTo(0, 0);
    },
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    reset() {
      this.additionalChargeName = "";
      this.paginationURL = `/dmsAdmin/get-all-additional-chargeName?workingCountry=${this.workingCountry}`;
    },

    async checkAdd() {
      this.operationName = "add-milestones";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          let workingCountry = [];
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              workingCountry.push(key.value);
            }
          });
          if (workingCountry.length === 1) {
            this.$router.push(`/add-new-additionalCharge`);
          } else {
            this.selectCountryDialog = true;
          }
        }
      } else {
        return;
      }
    },
    async checkApprove(id, sourceId, type, source, destination) {
      this.operationName = "delete-milestones";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.openApprove(id, sourceId, type);
        this.sourceCountry = source;
        this.destinationCountry = destination;
      } else {
        return;
      }
    },
    openApprove(id, sourceId, type) {
      this.additionalType = type;
      this.approvedId = id;
      this.sourceId = sourceId;
      this.dialog = true;
      this.title = "Approve Additional Charge";
      this.message =
        "Are you sure you want to approve this additional charge list?";
      this.options = Object.assign(this.options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async checkDelete(id, sourceId, type) {
      this.operationName = "delete-milestones";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.open(id, sourceId, type);
      } else {
        return;
      }
    },
    open(id, sourceId, type) {
      // console.log(type);
      this.additionalType = type;
      this.sourceId = sourceId;
      this.deleteId = id;
      this.dialog = true;
      this.title = "Delete Additional Charge";
      this.message =
        "Are you sure you want to delete this additional charge list?";
      this.options = Object.assign(this.options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getAdditionalChargeNameFromList(list) {
      let arrName = [];
      list.forEach((element) => {
        arrName.push(element.name);
      });
      return arrName.toString();
    },

    agree() {
      if (this.additionalType == "Delete") {
        this.deleteApproveMilestone(this.deleteId);
      } else {
        this.deleteApproveMilestone(this.approvedId);
      }
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
      this.additionalType = "";
      this.sourceId = "";
    },
    closeMessage() {
      this.x.success = false;
      // this.getAdditionalChargeList();
    },
    checkListStatus(arr, citystatus) {
      if (arr.includes("PENDING")) {
        return "PENDING";
      } else {
        if (citystatus == "PENDING") {
          return "PENDING";
        } else {
          return "APPROVED";
        }
      }
    },
    deleteApproveMilestone(id) {
      let url = editAdditionalCharge;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (this.additionalType === "Delete") {
        id.map((e) => {
          if (e) {
            e.status = "DELETED";
            return e;
          }
        });
      } else {
        id.map((e) => {
          if (e) {
            e.status = "APPROVED";
            return e;
          }
        });
      }
      // console.log(id);

      let body = {
        data: id,
        sourceId: this.sourceId,
      };
      if (this.additionalType !== "Delete") {
        body.sourceDestinationChange = true;

        body.destination = this.destinationCountry;
        body.source = this.sourceCountry;
      }

      this.axios.put(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.x.success = true;
          this.successDialogText =
            this.additionalType === "Delete"
              ? "Additional charge deleted successfully"
              : "Additional charge approved successfully";
          this.sourceId = "";
          // this.x.message = "Additional Charge Deleted Successfully.";
          this.refresh();
          //this.items = response.data.data;
        },
        (error) => {
          // this.x.error = "Failed to Delete Additional Charges.";
        }
      );
    },

    refresh() {
      this.componentKey = this.componentKey + 1;
    },

    searchData() {
      // console.log(search);
      console.log(this.additionalChargeName);
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      let url = [];
      url.push(`workingCountry=${this.workingCountry}`);
      if (this.additionalChargeName) {
        url.push(`name=${this.additionalChargeName}`);
      }

      let finalUrl = `/dmsAdmin/get-all-additional-chargeName?`;
      if (url.length > 0) {
        let params = url.join("&");
        finalUrl = finalUrl + params;
      } else return "";

      this.paginationURL = finalUrl;
    },
    // async updateUser(id, status) {
    //   this.loading = true;
    //   this.operationName = "block-milestone";
    //   let y = await this.checkOpertaionPermission(this.operationName);

    //   if (y) {
    //     let blockUrl = "/dmsAdmin/editMilestone";
    //     delete this.axios.defaults.headers.common["token"];
    //     let token = localStorage.getItem(StorageKeys.SessionKey);
    //     let config = {
    //       headers: {
    //         authorization: `bearer ${token}`,
    //       },
    //     };
    //     let body = {};
    //     let _status_ = "";
    //     body = {
    //       milestoneId: id.toString(),
    //       isBlock: status,
    //     };
    //     if (status == "true") {
    //       _status_ = "Milestone Blocked Successfully";
    //     } else {
    //       _status_ = "Milestone Unblocked Successfully";
    //     }

    //     this.axios.post(this.constants.apiUrl + blockUrl, body, config).then(
    //       (response) => {
    //         this.x.success = true;
    //         this.componentKey = this.componentKey + 1;
    //         this.loading = false;
    //         this.x.message = `${_status_}`;
    //         this.dialog = false;
    //       },
    //       (error) => {
    //         this.loading = false;
    //         this.x.error = error.response.data.message;
    //       }
    //     );
    //   } else {
    //     this.loading = false;
    //     return;
    //   }
    // },

    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
};
</script>

<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

#MilestoneListTable td {
  font-size: 16px !important;
  text-align: left;
}

thead {
  background: #fbfbfb;
}
</style>
<style scoped>
.payadvance {
  margin: 0 auto;
}
.title-layout-size {
  padding: 0px 16px !important;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.milestonebtn {
  margin: 0 !important;
  right: -75% !important;
}
.deletemilestone {
  margin: auto 0;
}
.cancel-btn {
  text-decoration: none;
  color: #ffffff;
  border: 2px solid red;
  padding: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f38282;
  border-radius: 5px;
}
.red-text {
  color: red;
  animation: animate 1.5s linear infinite;
}
.green-text {
  color: green;
}

/* .tooltip {
    font-size: 18px;
    font-weight: 500;
  } */
.tooltip-op {
  opacity: 1 !important;
}
.milestone-actions {
  padding: 0;
  margin: auto 6px auto 0px;
  cursor: pointer;
}
.main-checklist {
  padding: 10px;
}
.checklist-span {
  margin: 10px;
  background-color: #ffffff;
  border: 2px solid darkorange;
  color: darkorange;
  border-radius: 20px;
  padding: 5px 15px;
}
</style>
