<template>
  <v-container fluid>
    <v-card raised v-if="showScreen">
      <loading :active.sync="loading" />
      <v-card-title class="bg-clr" style="padding: 16px !important">
        <v-layout column wrap>
          <div class="headline">Milestone Section</div>
          <v-spacer />
          <div class="heading py-1 muted">
            Milestone helps in tracking assignments
          </div>
        </v-layout>
        <v-spacer />
        <v-btn
          v-permissions="'add-milestones'"
          @click="checkAdd()"
          class="white--text"
          flat
          color="orange darken-1"
        >
          <v-icon>add</v-icon>&nbsp;Add New Milestone
        </v-btn>
      </v-card-title>
      <v-layout row wrap align-center>
        <v-flex md12 class="communication">
          <v-card class="nospace">
            <v-card-text class="nospace">
              <v-data-table
                id="MilestoneListTable"
                hide-actions
                :headers="headers"
                :items="items"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    {{ props.index + 1 }}
                    <activityLogs :type="'milestone'" :_id="props.item._id" />
                  </td>
                  <td v-if="props.item.countrySortName">
                    {{ props.item.countrySortName.toString() }}
                  </td>
                  <td v-else>-</td>
                  <td>
                    <div>
                      {{ capitalize(props.item.pickupLocation) || "N.A" }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ capitalize(props.item.dropOffLocation) || "N.A" }}
                    </div>
                  </td>
                  <td style="padding: 20px !important">
                    <p
                      class="checklist-span"
                      :key="check._id"
                      v-for="(check, i) in props.item.checkList"
                    >
                      Checklist - {{ i + 1 }}:
                      {{ capitalize(check.name) || "N.A" }}
                    </p>
                  </td>
                  <td>
                    <v-tooltip
                      top
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          v-permissions="'delete-milestones'"
                          color="orange darken-1"
                          flat
                          prepend-icon
                          @click.native="checkDelete(props.item._id)"
                          class="milestone-actions"
                          title="Delete Milestone"
                          >delete</v-icon
                        >
                      </template>
                      <span class="tooltip">Delete Milestone</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          v-permissions="'block-milestone'"
                          v-if="!props.item.isBlocked"
                          color="orange darken-1"
                          flat
                          prepend-icon
                          @click.native="updateUser(props.item._id, 'true')"
                          class="milestone-actions"
                          title="Block Milestone"
                          >block</v-icon
                        >
                      </template>
                      <span class="tooltip">Block Milestone</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          v-permissions="'block-milestone'"
                          v-if="props.item.isBlocked"
                          color="red darken-1"
                          @click.native="updateUser(props.item._id, 'false')"
                          class="milestone-actions"
                          flat
                          prepend-icon
                          title="Unblock Milestone"
                          >clear</v-icon
                        >
                      </template>
                      <span class="tooltip">Unblock Milestone</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <ErrorBox :error="x.error" />
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="agree">Yes</v-btn>
          <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import PaginationButtons from "@/components/Pagination/Pagination-New";
import { milestoneList } from "@/constants/datatable-headers.js";
import activityLogs from "../Common/activityLogs";
import Loading from "vue-loading-overlay";
import { EventBus } from "../../event-bus.js";
import Swal from "sweetalert2";
import { StorageKeys } from "../../constants/constants";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import {
  milestoneListDetails,
  removeMilestone,
} from "../../constants/api-urls";
export default {
  components: {
    PaginationButtons,
    activityLogs,
    ErrorBox,
    SuccessDialog,
    Loading,
  },
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.getMilestones();
    });
    this.getMilestones();
  },
  data() {
    return {
      showScreen: true,
      workingCountry: null,
      operationName: "",
      loading: true,
      isNoSearchResult: false,
      componentKey: 0,
      headers: milestoneList,
      items: [],
      x: {
        error: null,
        message: null,
        success: false,
      },
      show: true,
      deleteId: null,
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: "#ffc04c",
        width: 290,
        zIndex: 200,
      },
    };
  },
  methods: {
    async checkAdd() {
      this.operationName = "add-milestones";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$router.push(`/add-milestone`);
      } else {
        return;
      }
    },
    async checkDelete(id) {
      this.operationName = "delete-milestones";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.open(id);
      } else {
        return;
      }
    },
    open(id) {
      this.deleteId = id;
      this.dialog = true;
      this.title = "Do You Want to Delete?";
      this.message = "Click Yes to Delete";
      this.options = Object.assign(this.options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.deleteMilestone(this.deleteId);
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
    closeMessage() {
      this.x.success = false;
      this.getMilestones();
    },
    getMilestones() {
      let url = milestoneListDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      this.axios
        .post(
          this.constants.apiUrl + url,
          { workingCountry: this.workingCountry, assignDriver: "false" },
          config
        )
        .then(
          (response) => {
            this.loading = false;
            this.items = response.data.data;
          },
          (error) => {
            this.x.error = "Failed to Fetch Milestones List.";
          }
        );
    },
    deleteMilestone(id) {
      let url = removeMilestone;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        milestoneId: id,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.x.success = true;
          this.x.message = "Milestone Deleted Successfully.";
          //this.items = response.data.data;
        },
        (error) => {
          this.x.error = "Failed to Delete Milestones.";
        }
      );
    },
    async updateUser(id, status) {
      this.loading = true;
      this.operationName = "block-milestone";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        let blockUrl = "/dmsAdmin/editMilestone";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {};
        let _status_ = "";
        body = {
          milestoneId: id.toString(),
          isBlock: status,
        };
        if (status == "true") {
          _status_ = "Milestone Blocked Successfully";
        } else {
          _status_ = "Milestone Unblocked Successfully";
        }

        this.axios.post(this.constants.apiUrl + blockUrl, body, config).then(
          (response) => {
            this.x.success = true;
            this.componentKey = this.componentKey + 1;
            this.loading = false;
            this.x.message = `${_status_}`;
            this.dialog = false;
          },
          (error) => {
            this.loading = false;
            this.x.error = error.response.data.message;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },

    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
};
</script>

<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

#MilestoneListTable td {
  font-size: 16px !important;
  text-align: left;
}

thead {
  background: #fbfbfb;
}
</style>
<style scoped>
.payadvance {
  margin: 0 auto;
}
.title-layout-size {
  padding: 0px 16px !important;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.milestonebtn {
  margin: 0 !important;
  right: -75% !important;
}
.deletemilestone {
  margin: auto 0;
}
/* .tooltip {
  font-size: 18px;
  font-weight: 500;
} */
.tooltip-op {
  opacity: 1 !important;
}
.milestone-actions {
  padding: 0;
  margin: auto 6px auto 0px;
  cursor: pointer;
}
.main-checklist {
  padding: 10px;
}
.checklist-span {
  margin: 10px;
  background-color: #ffffff;
  border: 2px solid darkorange;
  color: darkorange;
  border-radius: 20px;
  padding: 5px 15px;
}
</style>
